import * as React from 'react'
import { useTheme } from '@emotion/react'
import facepaint from 'facepaint'

const mq = facepaint([
  '@media(min-width: 600px)'
])

const headingStyles = ({ colors }) => mq({
  color: '#353945',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: ['2.3rem', '4rem'],
  lineHeight: ['3.3rem', '4.8rem'],

  textAlign: 'center',
  letterSpacing: '-0.03em'
})

const Heading = (props) => {
  const theme = useTheme()

  return (
    <h1 css={headingStyles(theme)} {...props}>
      {props.children}
    </h1>
  )
}

export default Heading
